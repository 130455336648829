
import { getReviewDetail, updateReview, deleteReview } from '@/api/review';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  mounted() {
    this.getReviewDetail();
  }

  private loading = true;

  private apiUrl = process.env.VUE_APP_BASE_API;

  private fileSizeLimit = 10;

  private form: any = {
    content: '',
    score: 0,
    photoes: [],
  }

  private goBack() {
    this.$router.push({ name: 'MyReview' });
  }

  private getReviewDetail() {
    this.loading = true;
    getReviewDetail(this.$route.params.idx).then((res) => {
      this.loading = false;
      this.form = res.data;
    });
  }

  private handleBeforeUpload(uploadFile:File, isPhoto:boolean) {
    const fileSizeLimitByMb = this.fileSizeLimit * 1024 * 1024;
    if (isPhoto) {
      const isImageFile = uploadFile.type.split('/')[0] === 'image';
      if (!isImageFile) {
        this.$message.warning('이미지 파일만 업로드 가능합니다.');
        return false;
      }
    }
    if (uploadFile.size > fileSizeLimitByMb) {
      this.$message.warning(`파일 업로드 최대용량은 ${this.fileSizeLimit}MB입니다.`);
      return false;
    }
    return true;
  }

  private handleRemoveImageFile(file: any) {
    const index = this.form.photoes.findIndex((imgFile: any) => imgFile.fileUid === file.fileUid);
    if (index > -1) this.form.photoes.splice(index, 1);
  }

  private handleSuccessUploadImageFile(res: any) {
    this.form.photoes.push({ fileUid: res.uid });
  }

  private handleExceed(files: any, fileList: any) {
      this.$message.warning('최대 파일 개수를 초과하였습니다.');
  }

  /* eslint-disable */
  private handleUpdate() {
    if (this.form.score < 1) {
      this.$message.warning('별점을 선택해주세요.');
      return false;
    }
    if (!this.form.content) {
      this.$message.warning('후기 내용을 입력해주세요.');
      return false;
    }
    // if (this.form.photoes.length < 1) {
      // this.$message.warning('후기 사진을 1장 이상 등록해주세요.');
      // return false;
    // }
    this.$confirm('정말 후기를 수정하시겠습니까?', {
      confirmButtonText: '수정',
      cancelButtonText: '취소',
    }).then(() => {
      updateReview(this.$route.params.idx, this.form).then(() => {
        this.$message.success('후기가 수정되었습니다.');
        this.$router.push({ name: 'MyReview' });
        // this.$router.push({ name: 'DeliveryDetail', params: { orderGroupId: this.$route.params.groupIdx, orderStatus: this.$route.params.orderStatus }});
      });
    });
  }

  private handleDelete() {
    this.$confirm('정말 후기를 삭제하시겠습니까?', {
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
    }).then(() => {
      deleteReview(this.$route.params.idx).then(() => {
        this.$message.success('후기가 삭제되었습니다.');
        this.$router.push({ name: 'MyReview' });
        // this.$router.go(-1);
      });
    });
  }
};
